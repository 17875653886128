.contact-section-one {
    background: transparent linear-gradient(43deg, #001B34 0%, #000428 36%, #133C6D 100%) 0% 0% no-repeat padding-box;
    padding-top: 112px;
    /* padding-bottom: 165px; */
    margin-top: -72px;
}

.contact-section-one .container-fluid {
    position: relative;
}

.contact-section-one .leftbox {
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    min-height: 870px;
    padding-top: 20%;

    position: absolute;
    /* top:100px; */
    left: 0;
}

.growth-point li {
    text-wrap: wrap;
    text-decoration: none;
    list-style: none;
}
.growth-point li span{
    font-weight: 600;
    font-size: 28px;
    color: var(--primary);
}
.icons{
    font-size: 50px; 
    display: flex; 
    gap: 50px !important; 
    align-items: center; 
    justify-content: center; 
}

.contact-section-one .small_heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: var(--font-one);
}

.contact-section-one h1 {
    font-size: 72px;
    font-weight: 700;
    line-height: 72px;
    color: var(--font-one);
}

.contact-section-one h1 span {
    color: var(--primary);
}

.contact-section-one .leftbox p {
    color: var(--font-one) !important;
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 22px !important;
}

.contact-section-one .rightbox {
    /* position: absolute;
    top:100px;
    right:0; */
    /* width:683px; */
    /* height: 825px; */
}

.contact-section-one .form-tab {
    display: flex;
    gap: 20px;
    margin: 30px auto;
    justify-content: center;
}

.contact-section-one .form-tab .item {
    display: flex;
    height: 90px;
    background: #FFFFFF;
    border-radius: 19px;
    width: 180px;
    align-self: end;
    flex-direction: column;
    justify-content: center;
    /* padding-left: 26px; */
    cursor: pointer;
    text-align: left;
    padding-left: 20px;
    transition: 0.3s all;
}

.contact-section-one .form-tab .item.active {
    /* height: 100px; */
    background: #F9D6A3;
    box-shadow: 0px 3px 10px #FFFFFF29;
}

/* .contact-section-one .form-tab .item.brand{
    width: 240px;
    padding-left: 60px;
} */
.contact-section-one .form-tab .item p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #000C4A;
}

.contact-section-one .form-tab .item h3 {
    font-size: 28px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000C4A;
    padding: 0;
    margin: 0;
}


.contact-section-one .form {
    background: transparent linear-gradient(209deg, #F89B15 0%, #F3AD47 73%, #F6E3CA 100%, #F6E6D0 100%) 0% 0% no-repeat padding-box;
    border-radius: 58px 0px 0px 0px;
    min-height: 700px;
    width: 100%;
    padding-bottom: 250px;
}

.contact-section-one .form .form-wrapper {
    max-width: 464px;
    margin: 0 auto;
    padding-top: 83px;
    padding-bottom: 100px;
}

.contact-section-one .form .form-wrapper .flex {
    /* gap: 10px; */
    row-gap: 10px;
}

.contact-section-one .btn-submit {
    background: transparent linear-gradient(304deg, #133D6E 0%, #010723 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #FFFFFF;
    width: 220px;
    text-align: center;
    height: 48px;
    transition: 0.3;
    opacity: 1;
    box-shadow: none;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
    border: 0;
    /* background: linear-gradient(to right, #fff 50%, #133D6E  50% ); */
    /* background-size: 200% 100%; */
    /* background-position: right bottom; */
    transition: all .5s ease-out;
    opacity: 0.8;
}

.contact-section-one .btn-submit:hover {
    /* background-position: left bottom; */
    opacity: 1;
}

.contact-section-one .form .custom-box {
    background: #E8F2FF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    min-height: 106px;
    padding: 16px;
}

.form .custom-box p {
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    font: normal normal normal 16px/30px Outfit;
    letter-spacing: 0px;
    color: #000C4A;
    opacity: 0.7;
}

.form .form-check-label {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #194C87;
}

.form .check-box-row {
    display: flex;
    flex-direction: row;
}

.form .form-check {
    min-height: 1.5rem;
    padding-left: 1em;
    margin-bottom: 0.125rem;
    display: flex;
    margin-right: 11px;
    margin-bottom: 10px;
}

.form .check-box-row span {
    display: flex;
    color: #000;
    font-size: 12px;
    line-height: 15px;
    max-width: 175px;
    background: url("./icons/info.png") no-repeat;
    padding-left: 18px;
    transition: 0.05s all;
    margin-top: 5px;
}

.form .check-box-row span span {
    background: none;
    padding: 0;
    width: 200px;
    display: none;
    margin-top: -7px;
}

.form .check-box-row span:hover span {
    display: flex;
    background: none;
}

.form .form-check input:checked {
    border-color: #000C4A;
    background-color: #000C4A;
    border-radius: 5px;
}

.form .info {
    font-size: 14px;
    line-height: 30px;
    font-weight: normal;
    color: #000C4A;
}



.form .form-check .form-check-input {
    margin-left: 0px;
    margin-right: 8px;
    box-shadow: none;
    min-width: 16px;
}


.form .purchase-date {
    position: relative;
}

.form .purchase-date::after {
    content: "";
    position: absolute;
    right: 5px;
    top: 14px;
    width: 24px;
    height: 24px;
    background: url("./icons/purchase-date.png") !important;
}

.form .react-datepicker-wrapper {
    width: 100%;
}


.form .amount {
    position: relative;
}

.form .amount::after {
    content: "";
    position: absolute;
    right: 5px;
    top: 14px;
    width: 24px;
    height: 24px;
    background: url("./icons/amount.png") !important;
}

.thank-you {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.thank-you img {
    width: 200px;
    max-width: 100%;
    margin: 0 auto;
}

.thank-you h1 {
    color: #061e5b;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 42px;
    font-weight: 600;
}

.thank-you p {
    color: #061e5b;
}



.form .form-floating>.form-control,
.form .form-floating>.form-select,
.form .form-floating>.form-control-plaintext {
    height: 52px;
    font-size: 16px;
    font-weight: 600;
    padding-top: 1.225rem;
    background: #E8F2FF;
    border-radius: 8px;
    color: #000C4A;
    line-height: 52px;
}

.form .form-control:focus,
.form .form-select:focus {
    border-color: var(--primary);
}

.form .form-floating>label,
.form .form-label {
    font-weight: 300;
    color: #000C4A;
    font-size: 16px;
    line-height: 20px;
}

.form .form-floating>.form-control:focus~label,
.form .form-floating>.form-select:focus~label,
.form .form-floating>.form-control:not(:placeholder-shown)~label,
.form .form-floating>.form-control-plaintext~label,
.form .form-floating>.form-select~label {
    box-shadow: none;
}

.form .form-floating>.form-control:focus,
.form .form-floating>.form-select:focus,
.form .form-floating>.form-control:not(:placeholder-shown),
.form .form-floating>.form-control-plaintext:focus,
.form .form-floating>.form-control-plaintext:not(:placeholder-shown) {
    box-shadow: none;

}

.form .btn-primary {
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    min-width: 200px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 25px;
}

.form .date-group.input-group>.form-select {
    max-width: 85px;
    text-align: center;
}

.form .invalid-feedback {
    /* position: absolute;
    top: 45px; */
    font-weight: 500;
}

.contact-section-one .form .custom-box .invalid-feedback {
    position: unset;
    top: unset;
}

.success-message {
    color: green;
    font-size: 44px;
    line-height: 58px;
    font-weight: 800;
}




.contact-section-two {
    background: transparent linear-gradient(193deg, #133C6D 0%, #071942 50%, #000428 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 163px 0px 0px;
    padding-bottom: 68px;
    margin-top: -158px;
    position: relative;
}

.contact-section-two .items {
    display: flex;
    gap: 60px;
    row-gap: 30px;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 150px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 60px;
}

.contact-section-two .items .item {
    width: 264px;
    height: auto;
    text-align: center;
}

.contact-section-two .items .item img {
    width: auto;
}

.contact-section-two .items .item p.title {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    color: var(--primary);
    margin-top: 10px;
    margin-bottom: 5px !important;
}

.contact-section-two .items .item p.description {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    color: var(--font-one);
    ;
    margin-top: 10px;
    margin-bottom: 10px !important;
}

.contact-section-two .items .item a {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #fff;
    padding-left: 0px;
}

.contact-section-two .items .item a:hover {
    color: var(--primary);
}




.contact-section-three {
    background: transparent linear-gradient(227deg, #E8F2FF 0%, #D6E7FF 100%) 0% 0% no-repeat padding-box;
    border-radius: 3px 180px 20px 20px;
    padding-bottom: 200px;
    margin-top: -158px;
    position: relative;

}

.contact-section-three .wrapper {
    max-width: 900px;
    margin: 0 auto;
}

.contact-section-three .section-header h3 {
    color: #03306E;
    text-align: left;
}

.faq-tab .nav-tabs {
    border: 0;
}

.faq-tab .nav-tabs .nav-link {
    font-size: 20px;
    line-height: 46px;
    font-weight: 600;
    color: #000000;
    padding: 0;
    margin-right: 20px;
}

.faq-tab .nav-tabs .nav-link.active {
    font-size: 24px;
    color: #000000;
    border-bottom: 3px solid #000C4A;
}

.faq-tab .accordion-item {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E2EEFF22 100%, #F0F0F0 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px 67px 10px 10px;
    padding: 15px;
    border: 0;
}

.faq-tab .accordion-button {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    background: none;
    border: 0 !important;
    border-radius: 0 !important;
    color: var(--primary);
    box-shadow: none !important;
}

.faq-tab .accordion-button.collapsed {
    color: #000C4A;
}

.faq-tab .accordion-body,
.faq-tab .accordion-body p,
.faq-tab .accordion-body ul li {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: #000000;
    padding-top: 0;
}

.faq-tab .accordion-body ul li {
    list-style: lower-alpha;
}


@media (max-width: 1337px) {
    /* .contact-section-one .leftbox{
        padding-left: 30px;
        max-width: 480px;
    } */

}

@media (max-width: 1140px) {
    /* .contact-section-one .rightbox{
        max-width: 50%;
    } */

}

@media (max-width: 768px) {
    .contact-section-one .leftbox {
        max-width: 100%;
        min-height: auto;
        justify-content: center;
        text-align: center;
        padding-top: 30px;
        padding-left: 0;
        top: unset;
    }

    /* .contact-section-one .rightbox{
        max-width: 100%;
        position: unset;
        width:auto;
        height: 860px;
    } */

    .contact-section-one .rightbox {
        margin-top: 255px;

    }

    .contact-section-one .form .form-wrapper {
        padding-left: 5px;
        padding-right: 5px;
    }


    .contact-section-one .form .form-wrapper .flex {
        flex-direction: column;
    }

    .contact-section-one .form .form-wrapper .flex .pe-1,
    .ps-1 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }


}

@media (max-width: 530px) {
    /* .contact-section-one .form-tab .item{
        padding-left: 10px !important;
        width: auto !important;
        padding-right: 10px;
    }
    .contact-section-one .form .form-wrapper{
        margin: 10px;
    } */
}

@media (max-width: 370px) {
    .contact-section-one .form-tab .item {
        height: auto !important;
        padding: 10px;
    }

    .contact-section-one .form-tab .item h3 {
        font-size: 17px;
        margin-bottom: 0;
        line-height: 24px;
    }

    .contact-section-one .form-tab .item p {
        font-size: 12px;
    }

    .contact-section-one .form-tab {
        gap: 4px;
    }
}

@media only screen and (min-width:321px) and (max-width:767px) {
    .contact-section-one .form-tab .item h3 {
        font-size: 21px;
        padding-right: 10px;
    }

    .contact-section-one .form-tab {
        gap: 10px;
    }
}

/* bannernew ui style */
.padding-left {
    padding-left: 80px
}

.contact-section-one .grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    justify-content: left;
    margin: 0;
    overflow: hidden;
    padding: 0;
    align-items: center;
}

.growth-point {
    font-size: 24px;
}

.growth-point ul li {
    margin-bottom: 10px;
}

.growth-point ul li::marker {
    color: #f09819;
    font-size: larger;
}

.contact-section-one .f-heading {
    font-size: 36px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 0;
}

.contact-section-one .f-heading span {
    color: var(--primary);
}

.check-banner-team {
    align-items: center;
    background-color: #09204A;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    margin-left: -80px;
    margin-top: 30px;
    margin-bottom: 200px;
    padding: 10px 30px 55px 80px;
    width: auto !important;
}

.check-banner-team p {
    font-size: 26px;
    margin-bottom: 20px !important;
    font-style: italic;
}

.banner-team {
    display: flex;
}

.banner-team p {
    font-size: 20px;
    margin-left: 20px;
    font-style: normal;
}

.banner-team span {
    font-size: 26px;
    color: #FFB31E;
}

.right-arrow img {
    width: 32px;
    float: right;
    cursor: pointer;
}

@media only screen and (min-width:280px) and (max-width:768px) {
    .padding-left {
        display: none;
    }
}

@media only screen and (min-width:769px) {
    .contact-section-one .leftbox {
        display: none;
    }
}