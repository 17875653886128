
.kwik-pass {
    background-color: var(--primary);
    margin-top: -72px;
    padding-top: 100px;
    padding-bottom: 10px;
    background-position: center;
    border-radius: 0 0 ;
    min-height: 600px;
}
.kwik-pass .heading {
    padding: 0 50px;
    color: #000428;
}
.first p {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 10px;
}
.heading h1 {
    font-weight: 700;
    font-size: 44px;
}
.second {
    font-weight: 300;
    font-size: 20px;
}
.btn {
    display: flex;
    gap: 30px;
}
.btn .cta {
    min-width: 125px;
    padding: 0px 20px !important;
    border-color: transparent;
    background: transparent linear-gradient(319deg, #00213D 0%, #0C3C67 100%) 0% 0% no-repeat padding-box !important;
    color: var(--font-one);
    min-height: 40px;
    margin-top: 20px;
    margin-left: -16px;
    border-radius: 44px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    transform: translate(0px);
    cursor: pointer;
}
.btn .cta2 {
    min-width: 191px;
    padding: -5px 15px !important;
    border: 1px solid #004B8D !important;
    color: #000428;
    min-height: 40px;
    margin-top: 20px;
    margin-left: -16px;
    border-radius: 44px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    transform: translate(0px);
    cursor: pointer;
    background-color: transparent !important;
}
.cta2 img {
    margin-right: 8px;
    width: 20px;
    height: auto;
}

.banner-bg {
  height: 60vh;
  width: 85vh;
  background-color: #FFE7CB;
  margin-bottom: 50px;
  border-radius: 20px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

/* Banner 1 */

/* Background Circle */
.circle-bg {
  position: absolute;
  top: var(--circle-top, 50%);  /* Change this dynamically */
  left: var(--circle-left, 50%); /* Change this dynamically */
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
/* Banner Content */
.banner-content {
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 1;
}

/* Side Images */
.side-images {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Banner Images */
.banner-img {
  width: 220px;
  height: auto;
  border-radius: 10px;
}

/* Center Image */
.center-img {
  width: 160px;
  height: auto;
  border-radius: 15px;
  position: relative;
  z-index: 2; /* Ensures it's in front */
}



.banner-content2 {
  display: flex;
  /* align-items: center; */
  /* gap: 20px; */
  z-index: 1;
  margin-bottom:100px;
}


/* Banner Images */
.banner-img {
  width: 220px;
  height: auto;
  border-radius: 10px;
}

.img1 {
  object-fit: contain;
  margin-bottom: 123px;
  margin-right: -5px;
}

.img2 {
  object-fit: contain;
  margin-top: 140px;
  margin-left: -15px;
}


.img3 {
  object-fit: contain;
  margin-top: 318px;
  margin-left: -31px;
}



.banner-content3 {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center horizontally */
  gap: 20px; /* Add spacing between images */
  z-index: 1;
  margin-bottom: 100px;
}

.img1c {

  margin-bottom: 123px;
  margin-right: -5px;
}

.img3c{
  position: relative;
  left: -160px;
}
/* 
.img2c {
  object-fit: contain;
  margin-top: 140px;
  margin-left: -15px;
}


.img3c {
  object-fit: contain;
  margin-top: 318px;
  margin-left: -31px;
}
 */







/* .banner-img {
    text-align: center;
} */
/* .banner-img img {
    width: 70%;
} */

/* @media screen and (max-width:995px){
    .banner{
        display: flex;
        flex-direction: column-reverse;
    }
} */
@media screen and (max-width:440px){
    .heading{
        text-align: center;
    }
    .first{
        margin-top: 20px;
    }
    .banner-slider{
        flex-direction: column;
    }
    .heading{
        padding: 0px 10px;
    }
    .banner-slider{
        padding: 0px 0px !important;
    }
   
}

.banner-slider {
  overflow: hidden;
  background: #f09819;
  padding: 10px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-left:60px;
  margin-top: 10px;
}

.slider-text {
  font-size: 18px;
  font-weight: 400;
  color: black;
  margin-right: 20px; /* Space between text and images */
  flex-shrink: 0;
}

.slider-container {
  overflow: hidden;
  position: relative;
  width: 100%;
 
}

.slider-track {
  display: flex;
  align-items: center;
  animation: scroll 20s linear infinite;
  margin-top: 10px;
}

.slider-img {
  height: 42px; /* Adjust as needed */
  margin: 0 15px;
  width: auto;
  flex-shrink: 0;
}

/* Smooth infinite scrolling */
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}


/* Mobile Fixes */
@media (max-width: 768px) {
  .banner-slider {
    flex-direction: column; /* Keep text & images in one row */
    align-items: center;
    justify-content: start;
    padding: 10px;
  }

  .slider-text {
    font-size: 14px;
    margin-right: 10px;
  }

  .slider-container {
    width: 80%; /* Ensure scrolling space */
  }

  .slider-track {
    animation: scroll 15s linear infinite;
    width: max-content; /* Ensures full visibility of images */
  }

  .slider-img {
    height: 30px; /* Slightly smaller on mobile */
    margin: 0 8px;
  }
}

  /* -----------------------------------------------section 2 -----------------------------------------------*/

  .section-2 {
    position: relative;
    background-color: #000428;
    padding-top: 80px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
    background-position: center;
    border-top-left-radius: 56px;
    border-top-right-radius: 56px;
    min-height: 610px;
    text-align: center;
    overflow: hidden;
   
}

.section-2::before {
    content: "";
    position: absolute;
    top: 600px;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    height: 500px;
    background: radial-gradient(circle, #09056b 30%, transparent 60%);
    border-radius: 50%;
    z-index: 0;
}

.container-fluid {
  position: relative;
}


@media (max-width: 768px) {
  .details{
    padding: 13px 40px;
    height: 235px !important;
    width: 1300px;
    display: flex;
    flex-direction: column;
  }
  .details h1{
    color: #F79D1C;
    font-size: 30px  !important;
    font-weight: 700;
    text-align: start;
  }
  .details p{
    text-align: left;
    line-height: 22px;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF60;
    padding-left: 30px;
  }
  .mobile{

  }
}




/* Parent container for all lines */
.cross-lines {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-60%, -50%);
}

/* Vertical line (main extension upwards) */
.vertical-line {
  position: absolute;
  width: 1px;
  height: 205px;
  background-color: #3366ff;
  top: -195px; /* Moves it above the div */
  left: 50%;
  transform: translateX(-50%);
}



/* Diagonal lines */
.diagonal-line {
  position: absolute;
  width: 1px;
  height: 500px;
  background-color: #3366ff;
  top: 50%;
  left: 50%;
  transform-origin: center;
}

/* Top-left diagonal */

/* Top-right diagonal */
.right-top {
  transform: translate(-50%, -50%) rotate(55deg);
}

/* Bottom-right diagonal */
.right-bottom {
  transform: translate(-50%, -50%) rotate(125deg);
}

/* Centered KwikPass Box */
.powering-details {
  height: 70px;
  width: 240px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #10163A;
  border-radius: 60px;
  border: 2px solid #09056b;
  padding: 0 20px;
  z-index: 10;
  position: relative;
}

.powering-details h1 {
  color: #ffffff;
  font-size: 35px;
  font-weight: 700;
}

.power-div{
  margin-top: 300px;
  margin-bottom: 200px;
}


  .top-text h1{
    font-size: 40px;
    font-weight: 700;
    color: #F79D1C;
  }
  .top-text p{
    font-size: 20px;
    font-weight: 300;
    color: #FFFFFF60;
  }
  .details{
    /* padding: 0 50px; */
    height: 100px;
    width: 1300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #03306E;
    border-radius: 60px;
  }
  .details h1{
    color: #F79D1C;
    font-size: 40px;
    font-weight: 700;
  }
  .details p{
    text-align: left;
    line-height: 22px;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF60;
  }

.shopify-sec-img{
  right: -18px;
  bottom: 110px;
}

.shopify-sec-head{
  right: -133px;
  bottom: 67px;
  width: 40vh;
  color:#F79D1C
}

.shopify-sec-text{
  right: -100px;
  bottom: 11px;
  width: 30vh;
  font-size: 13px;
  font-weight: 300;
}


.image-stack-container {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.overlapping-image {
  position: absolute;
  width: 350px; /* Adjust image size */
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}



.store-div{
  top:-42px
}

.store-sec-img{
  left: 345px;
  top: 10px;
}

.store-sec-head{
  left: 190px;
  bottom: -100px;
  width: 53vh;
  color: #F79D1C;
}

.store-sec-text{
  left: 238px;
  top: 95px;
  width: 40vh;  
  font-size: 13px;
  font-weight: 300;
}




.appmaker-div{
  bottom:-70px
}

.appmaker-sec-img{
  left: 345px;
  top: 10px;
}

.appmaker-sec-head{
  left: 190px;
  bottom: -100px;
  width: 53vh;
  color: #F79D1C;
}

.appmaker-sec-text{
  left: 238px;
  top: 95px;
  width: 40vh;  
  font-size: 13px;
  font-weight: 300;
}



.customweb-div{
  bottom: -62px;
  right: 722px;

}

.customweb-sec-img{
  left: 345px;
  top: 10px;
}

.customweb-sec-head{
  left: 190px;
  bottom: -100px;
  width: 53vh;
  color: #F79D1C;
}

.customweb-sec-text{
  left: 232px;
  top: 95px;
  width: 40vh;  
  font-size: 13px;
  font-weight: 300;
}




.mobileApp-div{
  top:-42px;
  right: 711px;
}

.mobileApp-sec-img{
  left: 345px;
  top: 10px;
}

.mobileApp-sec-head{
  left: 190px;
  bottom: -100px;
  width: 53vh;
  color: #F79D1C;
}

.mobileApp-sec-text{
  left: 232px;
  top: 95px;
  width: 40vh;  
  font-size: 13px;
  font-weight: 300;
}



.powering-btn{
  left: 272px;
  top: 95px;
  width: 30vh;  
  font-size: 12px;
  font-weight: 300;
}




  .same{
    gap: 15px;
  }
  .anonymous{
    border: 0 1px 0 1px solid #fff
  }
  .line{
    height: 46px;
    width: 1px;
    background-color: #ffffff29;
  }


  .third{
    background-color: #004B8D;
    border-radius: 96px;
    /* height: 740px; */
    padding: 60px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  .input-img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .input-text{
    text-align: left;
  }
  .input-text h1{
    font-size: 40px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.9);
  }
  .input-text .btn .cta2 {
    min-width: 191px;
    padding: -5px 15px !important;
    border: 1px solid #fff !important;
    color: #fff;
    min-height: 40px;
    margin-top: 20px;
    margin-left: -16px;
    border-radius: 44px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    transform: translate(0px);
    cursor: pointer;
}
.info {
  margin-top: 30px;
  gap: 0px;
}

.section {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid transparent; /* Default: No border */
  transition: border-bottom 0.3s ease-in-out; /* Smooth transition */
}

.section.active {
  border-bottom: 1px solid #dddddd46;
}


.head {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff9c;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  position: relative;
  padding-bottom: 5px;
  margin-top: 10px;
}

.head.active {
  font-weight: bold;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 0;
  transition: width 0.4s linear;
  margin-bottom: -2px;

}

.body {
  font-size: 16px;
  color: #FFFFFF54;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.growth{
  margin-top: 80px;
}
.icons{
  margin-top: 60px;
}
.icons p{
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF90;
  line-height: 120%;
}
.mar-btm{
  margin-bottom: 50px;
}
.toggle-head {
  gap: 40px;
  margin-bottom: 30px;
}
.toggle-option {
  letter-spacing: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff90;
  cursor: pointer;
  padding-bottom: 5px; 
  display: inline-block;
  transition: border-bottom 0.3s ease;
}
.toggle-option.active {
  color: #ffffff; 
  border-bottom: 1px solid #fff; 
}


.last-section {
  margin: 70px 0;
  padding: 20px 40px;
  background: linear-gradient(90deg, #09143d 60%, rgba(9, 20, 61, 0.7));
  border-radius: 60px;
  display: flex;
  align-items: center;
  text-align: left;
}
.last-section h4 {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}
.last-section p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffffb3;
}
.view {
  display: flex;
  align-items: center;
}
.view-img img {
  max-width: 140%;
  height: auto;
  margin-bottom: -20px;
  margin-right: 80px;
}
.cta-container {
  text-align: right;
}
.cta {
  min-width: 150px;
  padding: 10px 25px;
  border: none;
  background: #004889;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.cta:hover {
  background: #0055a5;
}


.faq-heading{
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: #000000;
}

/* ---------------------------------Section-3---------------------------------------------------------  */
.market-section {
  display: flex;
  justify-content: space-evenly;
  padding: 20px 180px;
}

@media (max-width:786px){
    .market-section{
      padding: 20px 100px;
      flex-direction: column;
      margin-bottom: 20px;
    }
}

.market-card {
  max-width: 300px;
  margin-bottom: 20px;
}

.headpass {
  border-left: 3px solid black;
  padding-left: 8px;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
  height: 15px;
  display: flex;
  align-items: center;
  color: black;
  
}

.contentpass {
  width: 195px;
  font-size: 14px;
  line-height: 1.4;
  color: black;
  margin-left: 10px;
  margin-top: 20px;

}



.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 20px 200px;
  color: black;
  
}


@media (max-width:786px){
  
.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  color: black;
  
  
}

.carousel-controls {
  position: absolute;
  right: 67px;
  top: 96%;
  transform: translateY(-50%);
  display: flex;
  gap: 10px;
  margin-top: 17px;
 
}

}


.carousel-card {
  flex: 0 0 auto;
  width: 300px;
  background: #FDA323;
  padding: 10px 5px; 
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: start;
  height: 480px;

}
.slick-list {
  margin: 0 -10px; /* Half of your gap */
}

.slick-slide {
  padding: 0 10px; /* Creates the gap */
}

.carousel-wrapper {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Hide scrollbar */
}

.carousel-wrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit browsers */
  
}


.carousel-controls {
  position: absolute;
  right: 67px;
  top: 96%;
  transform: translateY(-50%);
  display: flex;
  gap: 10px;
 
}



.section-4 {
  position: relative;
  background-color: #000428;
  padding-top: 50px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  background-position: center;
  border-top-left-radius: 56px;
  border-top-right-radius: 56px;
  min-height: 610px;
  text-align: center;
  overflow: hidden;
  padding: 50px 100px ;
  margin-top: 70px;

}



@media (max-width:786px) {
  .section-4 {
    position: relative;
    background-color: #000428;
    padding-top: 50px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
    background-position: center;
    border-top-left-radius: 56px;
    border-top-right-radius: 56px;
    min-height: 610px;
    text-align: center;
    overflow: hidden;
    padding: 50px 15px ;
  }

  .power-vid{
    display: flex;
    align-items: center;
    justify-content: center;
  }

}





.sec4-heading{
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
}


.sec-4head{
    color: #f09819;
    font-weight:500;
    display: flex;
    align-items: start;
}

.sec-4content{
    width: 400px;
    text-align: start;
    font-weight: 200;

}

.power-img{
  height: 502px;
  width: 366px;
}


.sec-heading{
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: #F79D1C;
}

.sec5-heading{
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: #000000;
}


.sec5-para{
  text-align: center;
  color: #000000;
  font-weight: 200;
  margin-bottom: 50px;
}


.card-head{
  color: black;
}


.SmartCard{
  background: #FDA323;
  padding: 5px 20px;
  width: 400px;
  margin: 5px;
}




.section-6 {
  position: relative;
  background-color: #000428;
  padding-top: 50px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  background-position: center;
  border-radius: 56px;
  min-height: 610px;
  text-align: center;
  overflow: hidden;
  padding: 50px 100px ;
}

.Testimo-Cards{
  background-color: #00074A;
  height: 450px;
  max-height: auto;
  width: 350px;
  margin-top: 20px;
}


.accord-card{
  background-color: "#FDA323";
  color: black;
  margin-top: 100px;
  width: 500px;
}

.faq-sec{
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.faq1-sec{
  display: flex;
  justify-content: center;
  text-align: center;
  color: black;
}

.resources-sec {
  background-color: #FDA323;
  display: flex;
  justify-content: center; /* Centers the cards */
  gap: 20px; /* Adds spacing between cards */
  padding: 20px;
}

.resources-card {
  background-color: #F79D1C;
  text-align: center;
;
  border-radius: 10px;
  width: 300px; /* Ensures consistent size */
}

.resources-card img {
  width: 100%;
  border-radius: 5px;
}

.resources-card p {
  margin-top: 10px;
  font-size: 16px;
  font-weight:500;
  text-align: left;
  color: black;
  padding: 0 15px
}



.last-section-six {
  margin: 70px 0;
  padding: 20px 40px;
  background: linear-gradient(90deg, #09143d 60%, rgba(9, 20, 61, 0.7));
  border-radius: 60px;
  display: flex;
  align-items: center;
  text-align: left;
}
.last-section-six h4 {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}
.last-section-six p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffffb3;
}



@media (max-width:786px) {
  .section-6{
    padding: 50px 5px;
  }

  
.last-section-six {
  margin: 70px 0;
  padding: 20px 40px;
  background: linear-gradient(90deg, #09143d 60%, rgba(9, 20, 61, 0.7));
  border-radius: 60px;
  display: flex;
  align-items: center;
  text-align: left;
}
.last-section-six h4 {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}
.last-section-six p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffffb3;
  margin-bottom: 20px;
}
}
